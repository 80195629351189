(function($, site) {

site.geo = site.geo || {};

site.geo.locate = function (success_handler, error_handler) {
  function setLocationData(data) {
    if (Object.prototype.toString.call(data) === '[object Array]') {
      return false;
    }

    if (data !== null && typeof data === 'object') {
      $.cookie('POSITION', JSON.stringify(data), {
        path: '/',
        secure: false
      });

      return true;
    }

    return false;
  }

  function getLocationData() {
    var undef;

    if ($.cookie('POSITION') === undef || $.cookie('POSITION') === null) {
      return false;
    }

    return JSON.parse($.cookie('POSITION'));
  }

  // able to get location
  var success_handler_wrapper = function(position, cached) {
    cached = typeof cached !== 'undefined' ?  cached : false;

    site.geo.hasCurrentLocation = true;
    var currentLocationData = {};

    // This is fresh data, so the 'position' variable is a geolocation object, NOT our cookie value
    // This object isn't able to be stored in a normal cookie
    if (!cached) {
      var lat = position.coords.latitude,
          lng = position.coords.longitude;

      currentLocationData = {
        latitude:  lat,
        lat:       lat,
        longitude: lng,
        lng:       lng
      };

      site.geo.currentLocationData = currentLocationData;

      // Store the location in a cookie to span both http/https
      setLocationData(currentLocationData);
    }

    // This is cached data, so we can simply use the input value to send to success handlers
    else {
      currentLocationData = position;
    }

    if (success_handler) {
      success_handler(currentLocationData);
    }
  };

  // unable to get location info
  var error_handler_wrapper = function() {
    if (error_handler) {
      error_handler();
    }
  };

  if ( window.blackberry && blackberry.location.GPSSupported ) {
    if ( typeof(blackberry.location.setAidMode) == 'undefined' ) {
      error_handler();
      return;
    }

    blackberry.location.setAidMode(2);

    var getCurrentPosition = function(successCallback,errorCallback,options) {
      var bb_blackberryTimeout_id;

      var bb_timeout = function handleBlackBerryLocationTimeout() {
        clearTimeout(bb_blackberryTimeout_id);
        errorCallback();
      };

      bb_blackberryTimeout_id = setTimeout( bb_timeout, 1000 );

      var handleBlackBerryLocation = function() {
        var lat = blackberry.location.latitude;
        var lng = blackberry.location.longitude;
        if (!lat && !lng) {
          errorCallback();
        }
        else {
          var pos = {};
          pos.coords = {
            latitude: lat,
            longitude: lng
          };

          successCallback(pos);
        }
      };

      blackberry.location.onLocationUpdate(handleBlackBerryLocation);
      blackberry.location.refreshLocation();
    };

    getCurrentPosition(success_handler, error_handler);
  }
  else {
    if (navigator.geolocation) {
      var storedData = getLocationData();
      if (!storedData) {
        navigator.geolocation.getCurrentPosition(success_handler_wrapper, error_handler_wrapper);
      }
      else {
        success_handler_wrapper(storedData, true);
      }
    }
  }
};

})(jQuery, window.site || {});
